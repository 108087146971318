import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select/creatable';
import { UploadAssetHooks } from '../../screens/trackDetails/hooks/TrackHooks';

function CustomSelect({ options, onChangeText, value, title, err }) {
  const [customInputValue, setCustomInputValue] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);

  const {
    RaedCatReq,
    SaveCatReq,
    AddTrackCategoryReq,
    RaedTagsReq,
    SaveTagsReq,
    AddTrackTagReq,
    isDropdownLoading,
  } = UploadAssetHooks();

  useEffect(() => {
    if (value && value.length > 0) setSelectedOptions(value);
  }, [value]);

  useEffect(() => {
    RaedCatReq();
    RaedTagsReq();
  }, []);

  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleCreate = (inputValue) => {
    const newOption = createOption(inputValue);
    setSelectedOptions(newOption);
    onChangeText([...selectedOptions, newOption]);

    if (title == 'Tags') {
      SaveTagsReq(newOption);
    } else {
      SaveCatReq(newOption);
    }
  };

  const handleInputChange = (inputValue) => {
    setCustomInputValue(inputValue);
  };

  // const handleKeyDown = (event) => {
  //   if (event.key === 'Enter' && customInputValue.trim() !== '') {
  //     const newOption = {
  //       value: customInputValue,
  //       label: 'Create ' + customInputValue,
  //     };

  //     setSelectedOptions([...selectedOptions, newOption]);
  //     onChangeText([...selectedOptions, newOption]);

  //     if (title == 'Tags') {
  //       setCategoriesArr((prev) => [...prev, newOption]);
  //       SaveTagsReq(customInputValue);
  //     } else {
  //       SaveCatReq(customInputValue);
  //     }

  //     setCustomInputValue('');
  //     event.preventDefault();
  //   }
  // };
  const customDropdownStyle = {
    singleValue: (styles) => ({
      ...styles,
      color: '#232840',
    }),
    valueContainer: (styles) => ({
      ...styles,
      // backgroundColor: "#000",
      // padding: "0px",
      // fontSize: "16px",
      // cursor: "pointer",
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: '#000',
      boxShadow: 'none',
      padding: '0px 10px',
      borderColor: '#D2D8E5',
      opacity: '0.4',
      '&:hover': { borderColor: 'transparent' },
      height: '48px',
      minHeight: '48px',
      width: '100%',
      // maxHeight: "665px",
      cursor: 'pointer',
      top: 7,
      borderColor: 'transparent',
      borderRadius: 10,
    }),
    indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: 'white',
      cursor: 'pointer',
      '&:hover': { color: 'white' },
      paddingRight: '0px',
    }),
    menu: (styles) => ({
      ...styles,
      left: '0',
      boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#fff',
      opacity: '1',
      borderRadius: '6px',
    }),
    menuList: (styles) => ({
      ...styles,
      backgroundColor: '#000',
      opacity: '40',
      maxHeight: '164px',
      padding: '0px 0px',
      borderRadius: '6px',
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: '400',
      color: '#FF',
      padding: '10px 16px 9px 16px',
      borderBottom: '1px solid #f4f4f4',
      backgroundColor: isFocused ? '#F3F4FA' : '#fff',
      '&:active': { backgroundColor: '#F3F4FA' },
    }),
    placeholder: (styles) => ({
      ...styles,
      fontWeight: '300',
      fontSize: '16px',
      color: 'white',
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: '#FBF5DF',
      margin: '1px 3px',
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: 'black',
      fontWeight: '400',
      fontSize: '15px',
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      display: 'none',
      color: '#EBCD60',
      padding: '8px 8px 8px 0px',
      '&:hover': { color: '#EBCD60', backgroundColor: '#FBF5DF' },
      svg: { width: '17px', height: '17px' },
    }),
  };
  return (
    <div className='w-[65vw]'>
      <label className='text-white'>{title}</label>
      <Select
        styles={customDropdownStyle}
        isMulti
        options={options}
        onChange={(newOptions) => {
          if (title === 'Tags')
            AddTrackTagReq(
              newOptions[newOptions.length - 1]?.value,
              newOptions
            );
          else
            AddTrackCategoryReq(
              newOptions[newOptions.length - 1]?.value,
              newOptions
            );
          setSelectedOptions(newOptions);
          onChangeText(newOptions);
        }}
        value={selectedOptions}
        inputValue={customInputValue}
        onInputChange={handleInputChange}
        isSearchable
        // isClearable
        isDisabled={isDropdownLoading}
        isLoading={isDropdownLoading}
        onCreateOption={handleCreate}
      />
      <label className='text-white mt-6'>{err}</label>
    </div>
  );
}

export default CustomSelect;
