import React, { useRef, useState, useEffect } from "react";
import Header from "../../components/common/Header";
import Button from "../../components/common/Button";
import Music from "../../assets/icons/Music.svg";
import Mic from "../../assets/icons/Mic.svg";
import { useNavigate } from "react-router-dom";
import { UploadAssetHooks } from "../trackDetails/hooks/TrackHooks";

const CreateTrack = () => {
    const navigate = useNavigate();
    const audioRef = useRef(null);
    const { handleFileChange } = UploadAssetHooks();

    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [musicURL, setMusicURL] = useState(null);

    // const handleFileChange = (e) => {
    //   const file = e.target.files[0];
    //   setSelectedFile(file);
    //   if (file) {
    //     setSelectedFile(file);
    //   }
    // };
    console.log('selectedAudioFile =', musicURL);

    const handleButtonClick = () => {
        // Trigger the click event of the hidden file input element.
        fileInputRef.current.click();
    };

    useEffect(() => {
        const accessToken = window.localStorage.getItem("token");
        // if (accessToken && accessToken !== "") {
        if (accessToken) {
            setIsLoggedIn(true);
        }
    }, []);

    return (
        <>
            {isLoggedIn && (
                <div>
                    <Header />
                    <div className="px-[8%] flex flex-col items-center pb-[5%]">
                        <div className="py-4 flex w-full gap-3 text-[14px]">
                            <p className="text-indigo-400 font-medium">Dashboard</p>
                            <p className="text-white font-medium">+</p>
                            <p className="text-white font-medium">Create Track</p>
                        </div>
                        <div className="flex flex-col justify-start items-center pt-[10%] w-full gap-8">
                            <p className="font-bold text-5xl text-white">
                                Create a New Track
                            </p>
                            <p className="text-center text-lg text-white text-opacity-50 max-w-[50%] ">
                                Lorem Ipsum is simply dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry's standard dummy
                                text ever since the 1500s,
                            </p>
                            <Button
                                title={"Upload Now"}
                                add={true}
                                onClick={
                                    () => {
                                        handleButtonClick();
                                    }
                                }
                            ></Button>
                            <input
                                value={""}
                                type="file"
                                className="hidden"
                                accept=".wav, .m4a, .mp3, .ogg"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                            />
                        </div>
                        <div className="h-[40vh] w-[55%] relative">
                            <img
                                src={Music}
                                className="absolute h-[60%] top-[10vh] right-0"
                                alt="music"
                            />
                            <img
                                src={Mic}
                                className="absolute h-[35%] bottom-0 left-[5vw]"
                                alt="mic"
                            />
                        </div>
                    </div>
                </div>
            )}

            {/* {!isLoggedIn && navigate("/")} */}
        </>
    );
};

export default CreateTrack;
