import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import Header from '../../components/common/Header';
import SongsTable from '../../components/dashboard/SongsTable';
import More10 from '../../assets/icons/more10.svg';
import Less10 from '../../assets/icons/less10.svg';
import Play from '../../assets/icons/play.svg';
import Sound from '../../assets/icons/Sound.svg';
import Cross from '../../assets/icons/Cross.svg';
import Union from '../../assets/icons/Union.svg';
import LyricPreview from '../trackDetails/LyricPreview';
import { TrackLyricHooks } from '../trackDetails/hooks/TrackLyricHooks';
import { useDispatch } from 'react-redux';
import { trackDurationAction } from '../../store/TrackDetails/TractDetailAction';
import { formatDuration } from '../../utils/HelperFuns';

function AllMyTracks() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [ismute, setIsMute] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(null);

  // const { trackData } = UploadAssetHooks();
  const { trackData } = TrackLyricHooks();
  const dispatch = useDispatch();

  let { url, trackDetails } = trackData;
  useEffect(() => {
    const accessToken = window.localStorage.getItem('token');
    // if (accessToken && accessToken !== "") {
    if (accessToken) {
      setIsLoggedIn(true);
    }
  }, []);

  const playPauseToggle = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      setDuration(audioRef.current.duration);
      audioRef.current.play().catch((error) => {
        console.error('Failed to play audio:', error);
      });
      // audioRef.current.src = musicUrl;
      // audioRef.current.load();
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleLoadedMetadata = () => {
    try {
      setDuration(audioRef.current.duration);
      // dispatch(trackDurationAction(audioRef.current.duration));
    } catch (error) {
      console.error('Error in handleLoadedMetadata:', error);
    }
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);
    }
  }, [audioRef, setDuration]);

  const skipBackward = () => {
    audioRef.current.currentTime = Math.max(
      0,
      audioRef.current.currentTime - 10
    );
  };

  const skipForward = () => {
    audioRef.current.currentTime = Math.min(
      duration,
      audioRef.current.currentTime + 10
    );
  };

  const updateTime = () => {
    setCurrentTime(audioRef.current.currentTime);
    if (audioRef.current.currentTime === audioRef.current.duration)
      setIsPlaying(!isPlaying);
  };
  const handleLoadedData = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  const closeModal = () => {
    setPreviewOpen((preState) => !preState);
    setCurrentTime(0);
    setIsPlaying(false);
    setDuration(0);
  };

  return (
    <>
      {isLoggedIn && (
        <div className='max-h-screen min-h-screen overflow-hidden'>
          <Header />
          <div className='px-[8%] h-[60vh]'>
            <div className='py-4 flex w-full gap-3 text-[14px]'>
              <p className='text-indigo-400 font-medium'>Dashboard</p>
              <p className='text-white font-medium'>+</p>
              <p className='text-white font-medium'>My Tracks</p>
            </div>
            <SongsTable setPreviewOpen={setPreviewOpen} />
          </div>
          <Modal
            className='custom-modal'
            isOpen={previewOpen}
            onRequestClose={closeModal}
          >
            <div className='w-full h-full overflow-hidden'>
              <div className='h-[25%]'>
                <div className='flex justify-between px-[5%] pt-[3%]'>
                  <p className='text-lg font-medium text-white'>Preview</p>
                  <img
                    src={Cross}
                    className='cursor-pointer'
                    onClick={closeModal}
                  />
                </div>
                <div className='flex justify-between mt-[5%] px-[5%]'>
                  <img
                    src={Less10}
                    onClick={skipBackward}
                    className='cursor-pointer'
                  />
                  <img
                    src={isPlaying ? Union : Play}
                    onClick={playPauseToggle}
                    className='cursor-pointer'
                  />
                  <img
                    src={More10}
                    onClick={skipForward}
                    className='cursor-pointer'
                  />

                  <div className='flex gap-2 items-center'>
                    <p className='text-white text-opacity-50 text-sm'>
                      {formatDuration(currentTime)}
                    </p>
                    <div className='h-[5px] w-[20vw] bg-white bg-opacity-50 rounded-md cursor-pointer'>
                      <div
                        className='h-full bg-blue-500 rounded-md cursor-pointe'
                        style={{ width: `${(currentTime / duration) * 100}%` }}
                      ></div>
                    </div>
                    <p className='text-white text-opacity-50 text-sm'>
                      {formatDuration(duration)}
                    </p>
                  </div>
                  <img
                    onClick={() => setIsMute(!ismute)}
                    src={Sound}
                    className='cursor-pointer'
                  />
                  {url && (
                    <audio
                      muted={ismute}
                      ref={audioRef}
                      onTimeUpdate={updateTime}
                      onLoadedData={handleLoadedData}
                      onError={(e) => console.error('Audio error:', url)}
                    >
                      <source src={url ? url : ''} type='audio/mpeg' />
                    </audio>
                  )}
                </div>
              </div>
              <div className='flex gap-3 h-[75%]'>
                <LyricPreview
                  noofArtists={3}
                  duration={duration}
                  currentTime={currentTime}
                  lyricArray={trackDetails.lyrics}
                />
              </div>
            </div>
          </Modal>
        </div>
      )}

      {/* {!isLoggedIn && navigate("/")} */}
    </>
  );
}

export default AllMyTracks;
