import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Toastify from '../../../components/Universal/Toastify';
import {
  allMyTracks,
  musicFileNameAction,
  musicUrlAction,
  readAllMyTracks,
  readTrackDetails,
  trackDetails,
  musicIdAction,
  currentTrackDetails,
  activeSectionAction,
  ResetTrackDetails,
  ResetLyrics,
} from '../../../store/TrackDetails/TractDetailAction';

export const useMyTracksHooks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let trackData = useSelector((state) => state.trackReducer);

  const handleTrackEdit = (track_id) => {
    dispatch(ResetTrackDetails());
    dispatch(ResetLyrics());
    dispatch(activeSectionAction(1));
    dispatch(
      readTrackDetails({
        apiPayloadRequest: track_id,
        callback: onReadTrackDetails,
      })
    );
  };

  const handleTrackPreview = (track_id) => {
    dispatch(ResetTrackDetails());
    dispatch(ResetLyrics());
    dispatch(
      readTrackDetails({
        apiPayloadRequest: track_id,
        callback: onSuccessTrackPreview,
      })
    );
  };
  const onSuccessTrackPreview = (response) => {
    // console.log(response.data[0]);
    dispatch(currentTrackDetails(response.data[0]));
    dispatch(musicIdAction(response.data[0]._id));
    dispatch(musicFileNameAction(response.data[0].track_name));
    dispatch(musicUrlAction(response.data[0].trackUrl));
  };

  const ReadMyTracks = () => {
    dispatch(
      readAllMyTracks({
        apiPayloadRequest: {},
        callback: onReadMyTracks,
      })
    );
  };

  const onReadMyTracks = (response) => {
    dispatch(allMyTracks(response.data));
  };

  const onReadTrackDetails = (response) => {
    if (response.data) {
      navigate(`/myTracks/edit/${response.data[0]._id}`);
      dispatch(currentTrackDetails(response.data[0]));
      dispatch(musicIdAction(response.data[0]._id));
      dispatch(musicFileNameAction(response.data[0].track_name));
      dispatch(musicUrlAction(response.data[0].trackUrl));
    }
  };

  return {
    ReadMyTracks,
    allTracks: trackData.allTracks,
    trackData,
    handleTrackPreview,
    handleTrackEdit,
  };
};
