import React, { useState } from 'react'
import TextInputField from '../../components/Universal/TextInputField';
import { useDispatch } from 'react-redux';
import { postSignUpAction } from '../../store/LoginAndRegister/LoginAndRegisterAction';
import { SignUpFormHooks } from './hooks/LoginHooks';
import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2'

const SignupForm = ({ setCurrentForm }) => {

    const {
        handleSignUpSubmit,
        handleChange,
        handleCheckboxChange,
        signupValues,
        termsAndConditions,
        viewPassword,
        viewRepeatPassword,
        setViewPassword,
        setViewRepeatPassword,
        signupErrors,
        isFieldRequired,
        isSubmitted,
        repeatPasswordValidation,
        setIsSubmitted,
        handlePhoneChange
    } = SignUpFormHooks(setCurrentForm);

    return (
        <form onSubmit={handleSignUpSubmit} className='py-10 px-12 bg-black bg-opacity-50 rounded-3xl lg:w-[400px]'>
            <p className='mb-10 text-4xl font-bold text-white'>Sign Up</p>
            <div className='flex flex-col gap-6'>
                <TextInputField
                    htmlFor={"phone"}
                    name={"phone"}
                    handleInputChange={handlePhoneChange}
                    value={signupValues.phone}
                    errorMessage={signupErrors.phone.message}
                    validateFunction={signupErrors.phone.validateFunction}
                    isFieldRequired={isFieldRequired("phone")}
                    isSubmitted={isSubmitted}
                    isPhoneInput={true}
                />
                <TextInputField
                    htmlFor={"email"}
                    name={"email"}
                    labelText={""}
                    placeholder={"Email ID"}
                    handleInputChange={handleChange}
                    value={signupValues.email}
                    errorMessage={signupErrors.email.message}
                    validateFunction={signupErrors.email.validateFunction}
                    isFieldRequired={isFieldRequired("email")}
                    isSubmitted={isSubmitted}
                />
                <TextInputField
                    htmlFor={"password"}
                    name={"password"}
                    labelText={""}
                    placeholder={"Password"}
                    type={!viewPassword ? "password" : "text"}
                    handleViewPassword={setViewPassword}
                    handleInputChange={handleChange}
                    value={signupValues.password}
                    errorMessage={signupErrors.password.message}
                    validateFunction={signupErrors.password.validateFunction}
                    isFieldRequired={isFieldRequired("password")}
                    isSubmitted={isSubmitted}
                />
                <TextInputField
                    htmlFor={"repeatPassword"}
                    name={"repeatPassword"}
                    labelText={""}
                    placeholder={"Repeat Password"}
                    type={!viewRepeatPassword ? "password" : "text"}
                    handleViewPassword={setViewRepeatPassword}
                    handleInputChange={handleChange}
                    value={signupValues.repeatPassword}
                    validateFunction={() => true}
                    repeatPasswordCheck={signupValues.password}
                    repeatPasswordFunction={repeatPasswordValidation}
                    isFieldRequired={isFieldRequired("repeatPassword")}
                    errorMessage={signupErrors.repeatPassword.message}
                    isSubmitted={isSubmitted}
                />
            </div>
            <div className='pt-6 flex ite'>
                <input
                    className='h-[12px] w-[12px] mt-[2px]'
                    type='checkbox'
                    checked={termsAndConditions}
                    onChange={handleCheckboxChange}
                />
                <label className='ml-2 relative text-xs gap-2 text-white '>
                    I Agree to the <span className='text-xs font-semibold text-yellow-400 cursor-pointer'>Terms & Conditions</span> and the
                    <span className='text-xs font-semibold text-yellow-400 cursor-pointer'> Privacy Policy</span>
                    {isSubmitted && !termsAndConditions && <p className="text-red-500  absolute bottom-[-18px] text-[10px] left-0">
                        Please Check The Checkbox
                    </p>}
                </label>
            </div>
            <div className='mt-8 flex flex-col gap-3'>
                <button type='submit' className='p-3 rounded-xl text-[15px] font-semibold bg-yellow-400' onClick={() => setIsSubmitted(true)}>
                    Create Account
                </button>
            </div>
            <div className='py-4 flex justify-center items-center'>
                <p className='text-white text-xs font-semibold'>Alread have an Account?
                    <span className='text-yellow-300 cursor-pointer' onClick={() => { setCurrentForm('login') }}> Sign In</span></p>
            </div>
        </form>
    )
}

export default SignupForm