import React, { useState } from 'react';
import './App.css';
import CreateTrack from './screens/createTrack/CreateTrack';
import Dashboard from './screens/dashboard/Dashboard';
import { TrackDetails } from './screens/trackDetails/TrackDetails';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './screens/Login/LoginPage';
import { useSelector } from 'react-redux';
import MyTracks from './screens/myTracks/MyTracks';

function App() {
  const [darkMode, setDarkMode] = useState(true);
  const loader = useSelector((state) => state.globalLoader.loading);
  return (
    <div
      className={
        darkMode
          ? 'bg-[#141227] h-full max-h-full  w-full'
          : 'bg-gray-600 bg-opacity-10 h-full w-full'
      }
    >
      {loader && (
        <div className='loader-container'>
          <div className='loader'></div>
        </div>
      )}
      <Router>
        <Routes>
          <Route path='/' element={<LoginPage />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/myTracks' element={<MyTracks />} />
          <Route path='/myTracks/edit/:id' element={<TrackDetails/>}/>
          <Route path='/createTrack' element={<CreateTrack />} />
          <Route path='/trackDetails' element={<TrackDetails />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
