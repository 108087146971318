import React, { useEffect, useState } from 'react';
import Button from '../common/Button';
import SongCard from './SongCard';
import { useMyTracksHooks } from '../../screens/myTracks/hooks/MyTracksHook';

const SongsTable = ({ setPreviewOpen }) => {
  const [moreCards, setMoreCards] = useState(true);
  const { ReadMyTracks, allTracks, handleTrackPreview, handleTrackEdit } =
    useMyTracksHooks();
  useEffect(() => {
    ReadMyTracks();
  }, []);

  const handlePrevw = (track_id) => {
    setPreviewOpen(true);
    handleTrackPreview(track_id);
  };
  return (
    <>
      <div className='flex justify-between pt-10 pb-5'>
        <p className='text-3xl text-white font-bold items-center'>My Tracks</p>
        <Button title={'This week'} dropdown={true} />
      </div>
      <div className='py-6 border-b border-white border-opacity-10 flex w-full'>
        <p className='w-[30vw] text-sm text-white text-opacity-50'>
          Track Name
        </p>
        <p className='w-[20vw] text-sm text-white text-opacity-50'>
          Recordings
        </p>
        <p className='w-[25vw] text-sm text-white text-opacity-50'>
          Average Recordings
        </p>
        <p className='w-[20vw] text-sm text-white text-opacity-50'>Likes</p>
        <div className='w-[5vw]'></div>
      </div>
      <div className='py-2 pb-4 relative h-full overflow-y-scroll'>
        {allTracks && allTracks.length ? (
          allTracks.map((track) => (
            <div key={track._id}>
              <SongCard
                track={track}
                editable={true}
                handleTrackPreview={handlePrevw}
                handleTrackEdit={handleTrackEdit}
              />
            </div>
          ))
        ) : (
          <>
            {
              <>
                <div className='text-white w-full text-opacity-50 text-center p-8'>
                  Nothing to show
                </div>
              </>
            }
            {/* {moreCards ? (
              <div className='p-8 flex justify-center absolute bottom-0 w-full more-container'>
                <Button
                  title={'More Results'}
                  onClick={() => setMoreCards(false)}
                />
              </div>
            ) : (
              <></>
            )} */}
          </>
        )}
        {/* {allTracks && allTracks.length && (
          <div className='p-8 flex justify-center absolute bottom-0 w-full more-container'>
            <Button
              title={'More Results'}
              onClick={() => setMoreCards(false)}
            />
          </div>
        )} */}
      </div>
    </>
  );
};

export default SongsTable;
