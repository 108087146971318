import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { combineReducers, createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import GlobalLoaderReducer from './store/GlobalLoader/GlobalLoaderReducer';
import { TrackReducer } from './store/TrackDetails/TrackReducer';
import { LyricReducer } from './store/TrackDetails/TrackReducer';
import { webportalAppRootSaga } from './store';
import { UserReducer } from './store/UserData/UserDataReducer';

const reducers = combineReducers({
  globalLoader: GlobalLoaderReducer,
  trackReducer: TrackReducer,
  lyricReducer: LyricReducer,
  userReducer: UserReducer,
});

const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducers, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(webportalAppRootSaga);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  // </React.StrictMode>
);
