import React from 'react';
import { MdEdit } from 'react-icons/md';
import { BsFillEyeFill } from 'react-icons/bs';

const SongCard = ({ editable, track, handleTrackEdit, handleTrackPreview }) => {
  return (
    <div className='p-2 mt-6 card-gradient rounded-2xl flex'>
      <div className='w-[30vw] flex gap-4'>
        <div className='h-24 w-28 bg-slate-200 rounded-xl'></div>
        <div className='flex flex-col gap-3 justify-between'>
          <p className='text-xl font-semibold text-white w-[15vw]'>
            {track?.track_name ?? ' Oh Rendu Prema Meghalu Ila'}
          </p>
          <div className='flex gap-2'>
            <div className='h-5 w-5 rounded-full bg-pink-500'></div>
            <p className='text-white text-opacity-50 text-[14px]'>
              {track?.singer_name ?? 'Sri Rama Chandra'}
            </p>
          </div>
        </div>
      </div>
      <div className='w-[20vw] flex gap-3 p-4 items-center'>
        <div className='h-2 w-2 rounded-full bg-violet-600'></div>
        <p className='text-white text-opacity-50 text-xs'>2.1M views</p>
      </div>
      <div className='w-[25vw] flex gap-3 p-4 items-center'>
        <div className='h-2 w-2 rounded-full bg-green-400'></div>
        <p className='text-white text-opacity-50 text-xs'>10.42 (9.86%)</p>
      </div>
      <div className='w-[20vw] flex gap-3 p-4 items-center'>
        <div className='h-2 w-2 rounded-full bg-violet-300'></div>
        <p className='text-white text-opacity-50 text-xs'>23,899 k</p>
      </div>
      <div className='w-[5vw] flex justify-around items-center text-white text-opacity-50 text-md'>
        {editable && (
          <>
            <div
              className='cursor-pointer p-1 '
              onClick={() => handleTrackEdit(track._id)}
            >
              <MdEdit />
            </div>
            <div
              className='cursor-pointer p-1'
              onClick={() => handleTrackPreview(track._id)}
            >
              <BsFillEyeFill />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SongCard;
