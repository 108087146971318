import * as actionTypes from '../ActionTypes';

export const musicIdAction = (payload) => {
  return {
    type: actionTypes.MUSIC_ID,
    payload: payload,
  };
};

export const trackDurationAction = (payload) => {
  return {
    type: actionTypes.SET_TRACK_DURATION,
    payload: payload,
  };
};

export const musicFileNameAction = (payload) => {
  return {
    type: actionTypes.MUSIC_FILENAME,
    payload: payload,
  };
};

export const musicUrlAction = (payload) => {
  return {
    type: actionTypes.MUSIC_URL,
    payload: payload,
  };
};
export const activeSectionAction = (payload) => {
  return {
    type: actionTypes.ACTIVE_SECTION,
    payload: payload,
  };
};
export const categoriesAction = (payload) => {
  return {
    type: actionTypes.CATEGORIES,
    payload: payload,
  };
};

export const tagsAction = (payload) => {
  return {
    type: actionTypes.TAGS,
    payload: payload,
  };
};

export const uploadAssetAction = (payload) => {
  return {
    type: actionTypes.UPLOAD_ASSET,
    payload: payload,
  };
};

export const TrackUpdateAction = (payload) => {
  return {
    type: actionTypes.TRACK_UPDATE,
    payload: payload,
  };
};

export const TrackSaveAction = (payload) => {
  return {
    type: actionTypes.TRACK_SAVE,
    payload: payload,
  };
};

export const AddTrackCategoryAction = (payload) => {
  return {
    type: actionTypes.ADD_TRACK_CATEGORY,
    payload: payload,
  };
};
export const AddTrackTagAction = (payload) => {
  return {
    type: actionTypes.ADD_TRACK_TAG,
    payload: payload,
  };
};

export const AddArtistAction = (payload) => {
  return {
    type: actionTypes.ADD_ARTIST,
    payload: payload,
  };
};

export const CreateNewCategory = (payload) => {
  return {
    type: actionTypes.SAVE_CAT,
    payload: payload,
  };
};

export const ReadTagsAction = (payload) => {
  return {
    type: actionTypes.READ_TAGS,
    payload: payload,
  };
};

export const SaveTagsAction = (payload) => {
  return {
    type: actionTypes.SAVE_TAGS,
    payload: payload,
  };
};

export const ReadCatAction = (payload) => {
  return {
    type: actionTypes.READ_CAT,
    payload: payload,
  };
};

export const SaveCatAction = (payload) => {
  return {
    type: actionTypes.SAVE_TRACK_LYRIC,
    payload: payload,
  };
};

export const currentTrackDetails = (payload) => {
  return {
    type: actionTypes.TRACK_DETAILS,
    payload: payload,
  };
};

export const ResetTrackDetails = () => {
  return { type: actionTypes.RESET_TRACK_DETAILS };
};

export const updateTrackDetails = (payload) => {
  return {
    type: actionTypes.UPDATE_TRACK_DETAILS,
    payload: payload,
  };
};

// Track lyrics actions
export const saveLyricsValue = (payload) => ({
  type: actionTypes.SAVE_LYRIC_VAL,
  payload: payload,
});

// export const setToTime = (toTime) => ({
//     type: actionTypes.SET_TO_TIME,
//     payload: toTime,
// });

// export const setLyricText = (lyricText) => ({
//     type: actionTypes.SET_LYRIC_TEXT,
//     payload: lyricText,
// });

// export const setLyricArtist = (lyricArtist) => ({
//     type: actionTypes.SET_LYRIC_ARTIST,
//     payload: lyricArtist,
// });

export const saveLyricsData = (payload) => {
  return {
    type: actionTypes.SAVE_TRACK_LYRIC,
    payload: payload,
  };
};

export const deleteEachLyric = (payload) => {
  return {
    type: actionTypes.DELETE_LYRIC,
    payload: payload,
  };
};

export const getLyricListAction = (payload) => {
  return {
    type: actionTypes.READ_LYRIC,
    payload: payload,
  };
};

export const lyricList = (payload) => {
  return {
    type: actionTypes.LYRIC_LIST,
    payload: payload,
  };
};

export const ResetLyrics = (payload) => {
  return {
    type: actionTypes.RESET_LIST,
    payload: payload,
  };
};

export const lyricEditingAction = (payload) => {
  return {
    type: actionTypes.EMAIL_VERIFICATION_REQUEST,
    payload: payload,
  };
};

export const lyricTiming = (payload) => {
  return {
    type: actionTypes.RESEND_VERIFICATION_REQUEST,
    payload: payload,
  };
};

export const trackUpdate = (payload) => {
  return {
    type: actionTypes.RESEND_VERIFICATION_REQUEST,
    payload: payload,
  };
};

// all my tracks
export const readAllMyTracks = (payload) => {
  return {
    type: actionTypes.READ_ALL_MY_TRACKS,
    payload: payload,
  };
};

export const allMyTracks = (payload) => {
  return {
    type: actionTypes.ALL_MY_TRACKS,
    payload: payload,
  };
};

export const readTrackDetails = (payload) => {
  return {
    type: actionTypes.TRACK_DETAIL_REQUEST,
    payload: payload,
  };
};
