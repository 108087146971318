import Axios from '../../utils/axios';

const accessToken = window.localStorage.getItem('token');
let headers = {
  Authorization: `Bearer ${accessToken}`,
  'Content-Type': 'multipart/form-data',
};
let commonheaders = {
  Authorization: `Bearer ${accessToken}`,
  'Content-Type': 'application/x-www-form-urlencoded',
};

export const uploadAsset = async (payload) => {
  const URL = '/uploadAsset';
  try {
    if (payload) {
      const res = await Axios.post(URL, payload, {
        headers,
      });
      console.log('uploadres..', res);
      return res.data;
    }
  } catch (e) {
    return console.log('uploaderror', e);
  }
};

export const trackSave = async (payload) => {
  console.log('payload =', payload);
  const URL = '/track/save';
  const res = await Axios.post(URL, payload, {
    'Content-Type': 'application/x-www-form-urlencoded',
  });
  return res.data;
};

export const trackUpdate = async (payload) => {
  const URL = '/track/update';
  const res = await Axios.post(URL, payload, { commonheaders });
  return res.data;
};

export const trackCategorySave = async (payload) => {
  const URL = '/track/category/save';
  const res = await Axios.post(URL, payload, { commonheaders });
  return res.data;
};

export const trackTagSave = async (payload) => {
  const URL = '/track/tag/save';
  const res = await Axios.post(URL, payload, { commonheaders });
  return res.data;
};

export const tagsRead = async (payload) => {
  const URL = '/tags/read';
  const res = await Axios.post(URL, payload, { commonheaders });
  return res.data;
};

export const tagsSave = async (payload) => {
  const URL = '/tags/save';
  const res = await Axios.post(URL, payload);
  return res.data;
};
export const catRead = async (payload) => {
  const URL = '/categories/read';
  const res = await Axios.post(URL, payload, { commonheaders });
  return res.data;
};

export const catSave = async (payload) => {
  const URL = '/categories/save';
  const res = await Axios.post(URL, payload, { commonheaders });
  return res.data;
};

export const lyricSave = async (payload) => {
  const URL = '/track/lyric/save';
  const res = await Axios.post(URL, payload, { commonheaders });
  return res.data;
};

export const lyricUpdate = async (payload) => {
  const URL = '/track/lyric/update';
  const res = await Axios.post(URL, payload, { commonheaders });
  return res.data;
};

export const lyricDelete = async (payload) => {
  const URL = '/track/lyric/delete';
  const res = await Axios.post(URL, payload, { commonheaders });
  return res.data;
};

export const readLyric = async (payload) => {
  const URL = '/track/read';
  const res = await Axios.post(URL, payload, { commonheaders });
  return res.data;
};

export const readAllTracks = async (payload) => {
  const URL = '/track/read';
  const res = await Axios.post(URL, payload, { commonheaders });
  return res.data;
};

export const readTrackDetails = async (payload) => {
  const data = `_id=${payload}`;
  const URL = '/track/read';
  const res = await Axios.post(URL, data, { commonheaders });
  return res.data;
};
