import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getUserProfileData,
  setUserProfileUrl,
} from '../../../store/UserData/UserDataAction';

function useUserDataHook() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    navigate('/');
  };

  const fetchUserProfile = () => {
    dispatch(
      getUserProfileData({
        apiPayloadRequest: {},
        callback: onSuccuss,
      })
    );
  };

  const onSuccuss = (response) => {
    dispatch(setUserProfileUrl(response.data[0].profilePicUrl));
  };

  return { logout, fetchUserProfile };
}

export default useUserDataHook;
