import React from 'react'
import TextInputField from '../../components/Universal/TextInputField';

const ForgotPasswordForm = ({ setCurrentForm }) => {

    const handleChange = () => {
        console.log("Changed")
    }

    return (
        <div className='py-10 px-12 bg-black bg-opacity-50 rounded-3xl lg:w-[400px]'>
            <p className='mb-10 text-4xl font-bold text-white'>Forgot Password</p>
            <div className='flex flex-col gap-6'>
                <TextInputField
                    htmlFor={"emailOrPhone"}
                    name={"emailOrPhone"}
                    labelText={"Email or Phone"}
                    placeholder={"Email or Phone"}
                    type={"text"}
                    handleInputChange={handleChange}
                    validateFunction={() => true}
                />
            </div>
            <div className='mt-4 flex flex-col gap-3'>
                <button className='p-3 rounded-xl text-[15px] font-semibold bg-yellow-400'>
                    Reset Password
                </button>
                <button
                    className='p-3 rounded-xl text-[15px] font-semibold border border-yellow-400 text-yellow-400'
                    onClick={() => { setCurrentForm("login") }}
                >
                    Cancel
                </button>
            </div>
        </div>
    )
}

export default ForgotPasswordForm