export const APP_LOADING = 'APP_LOADING';
export const DASHBOARD_ACTION_GET_NEW_DATA = 'DASHBOARD_ACTION_GET_NEW_DATA';

//Login and Sign Up
export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const EMAIL_VERIFICATION_REQUEST = 'EMAIL_VERIFICATION_REQUEST';
export const RESEND_VERIFICATION_REQUEST = 'RESEND_VERIFICATION_REQUEST';

//music reducer
export const MUSIC_ID = 'MUSIC_ID';
export const MUSIC_URL = 'MUSIC_URL';
export const MUSIC_FILENAME = 'MUSIC_FILENAME';
export const ACTIVE_SECTION = 'ACTIVE_SECTION';
export const CATEGORIES = 'CATEGORIES';
export const TAGS = 'TAGS';

// Track Data
export const TRACK_SAVE = 'TRACK_SAVE';
export const UPLOAD_ASSET = 'UPLOAD_ASSET';
export const SET_TRACK_DURATION = 'SET_TRACK_DURATION';
export const TRACK_UPDATE = 'TRACK_UPDATE';
export const ADD_TRACK_CATEGORY = 'ADD_TRACK_CATEGORYS';
export const ADD_TRACK_TAG = 'ADD_TRACK_TAG';
export const ADD_ARTIST = 'ADD_ARTIST';
export const READ_TAGS = 'READ_TAGS';
export const SAVE_TAGS = 'SAVE_TAGS';
export const READ_CAT = 'READ_CAT';
export const SAVE_CAT = 'SAVE_CAT';
export const TRACK_DETAIL_REQUEST = 'TRACK_DETAIL_REQUEST';
export const SONG_BOOK_UPLOAD_REQUEST = 'SONG_BOOK_UPLOAD_REQUEST';
export const LYRIC_EDITING_REQUEST = 'LYRIC_EDITING_REQUEST';
export const LYRIC_TIMING_REQUEST = 'LYRIC_TIMING_REQUEST';
export const RESET_TRACK_DETAILS = 'RESET_TRACK_DETAILS';

//Track Lyric Data
export const SAVE_TRACK_LYRIC = 'SAVE_TRACK_LYRIC';
export const SAVE_LYRIC_VAL = 'SAVE_LYRIC_VAL';
export const DELETE_LYRIC = 'DELETE_LYRIC';
export const READ_LYRIC = 'READ_LYRIC';
export const LYRIC_LIST = 'LYRIC_LIST';
export const RESET_LIST = 'RESET_LIST';
// export const SET_FROM_TIME = "SET_FROM_TIME";
// export const SET_TO_TIME = 'SET_TO_TIME';
// export const SET_LYRIC_TEXT = 'SET_LYRIC_TEXT';
// export const SET_LYRIC_ARTIST = 'SET_LYRIC_ARTIST';

// All My Tracks
export const READ_ALL_MY_TRACKS = 'READ_ALL_MY_TRACKS';
export const ALL_MY_TRACKS = 'ALL_MY_TRACKS';
export const TRACK_DETAILS = 'TRACK_DETAILS';
export const UPDATE_TRACK_DETAILS = 'UPDATE_TRACK_DETAILS';

//User Data

export const USER_PROFILE_DATA = 'USER_PROFILE_DATA';
export const USER_RESET = 'USER_RESET';
export const USER_PROFILE_URL = 'USER_PROFILE_URL';
