import * as actionTypes from '../ActionTypes';

const INITIAL = {
  id: null,
  url: null,
  filename: null,
  activeSection: 1,
  trackDuration: 0,
  categories: [],
  tags: [],
  allTracks: [],
  trackDetails: { noofArtists: '0', categories: [], tags: [] },
};

export const TrackReducer = (state = INITIAL, action) => {
  switch (action.type) {
    case actionTypes.MUSIC_ID:
      return { ...state, id: action.payload };
    case actionTypes.MUSIC_FILENAME:
      return { ...state, filename: action.payload };
    case actionTypes.MUSIC_URL:
      return { ...state, url: action.payload };
    case actionTypes.SET_TRACK_DURATION:
      return { ...state, trackDuration: action.payload };
    case actionTypes.ACTIVE_SECTION:
      return { ...state, activeSection: action.payload };
    case actionTypes.CATEGORIES:
      return { ...state, categories: action.payload };
    case actionTypes.TAGS:
      return { ...state, tags: action.payload };
    case actionTypes.ALL_MY_TRACKS:
      return { ...state, allTracks: action.payload };
    case actionTypes.RESET_TRACK_DETAILS:
      return {
        ...state,
        trackDetails: { noofArtists: '0', categories: [], tags: [] },
      };

    case actionTypes.TRACK_DETAILS:
      return {
        ...state,
        trackDetails: { ...state.trackDetails, ...action.payload },
      };

    case actionTypes.UPDATE_TRACK_DETAILS:
      if (action.payload.field)
        return {
          ...state,
          trackDetails: {
            ...state.trackDetails,
            [action.payload.field]: action.payload.data,
          },
        };
    default:
      return state;
  }
};

const lyricInitialState = {
  data: null,
  lyricList: [],
};

export const LyricReducer = (state = lyricInitialState, action) => {
  // console.log('action.payload =', action.payload);
  switch (action.type) {
    case actionTypes.SAVE_LYRIC_VAL:
      return { ...state, data: action.payload };
    case actionTypes.READ_LYRIC:
      return { ...state, data: action.payload };
    case actionTypes.DELETE_LYRIC:
      return { ...state, data: action.payload };
    case actionTypes.LYRIC_LIST:
      return { ...state, lyricList: action.payload };
    case actionTypes.RESET_LIST: {
      return { ...state, lyricList: [] };
    }
    default:
      return state;
  }
};
