import React from 'react';

function timeToSeconds(timeString) {
  var [minutes, seconds] = timeString.split(':').map(Number);
  var totalSeconds = minutes * 60 + seconds;
  return totalSeconds;
}

function timeDifferenceInSeconds(fromTime, toTime) {
  var [fromMinutes, fromSeconds] = fromTime.split(':').map(Number);
  var [toMinutes, toSeconds] = toTime.split(':').map(Number);

  var totalFromSeconds = fromMinutes * 60 + fromSeconds;
  var totalToSeconds = toMinutes * 60 + toSeconds;

  var timeDifference = totalToSeconds - totalFromSeconds;
  return timeDifference;
}

function isCurrentTimeInRange(currentTime, fromTime, toTime) {
  return (
    currentTime >= convertTimeToSeconds(fromTime) &&
    currentTime <= convertTimeToSeconds(toTime)
  );
}

function convertTimeToSeconds(timeString) {
  const [minutes, seconds] = timeString.split(':').map(Number);
  return minutes * 60 + seconds;
}

function Artist({ idx, lyric, duration }) {
  let { fromTime, toTime } = lyric;
  const start = (timeToSeconds(fromTime) / duration) * 100;
  const difference =
    (timeDifferenceInSeconds(fromTime, toTime) / duration) * 100;

  if (idx === 2)
    return (
      <div
        style={{
          position: 'absolute',
          height: `calc(${difference}% + 30px)`,
          top: `${start}%`,
        }}
        className={`bg-[#58CF7B] bg-opacity-30 w-full top-0 min-h-[30px]`}
      >
        <div className='w-full p-2 bg-gradient-to-b from-[#58CF7B] to-[#23BFB4]'>
          <p className='text-center w-full text-[10px]'>ARTIST 2</p>
        </div>
      </div>
    );

  if (idx === 3)
    return (
      <div
        style={{
          position: 'absolute',
          height: `calc(${difference}% + 30px)`,
          top: `${start}%`,
        }}
        className={`bg-[#E08EBF] bg-opacity-30 w-full top-0 min-h-[30px]`}
      >
        <div className='w-full p-2 bg-gradient-to-b from-[#FE98B9] to-[#505EDC]'>
          <p className='text-center w-full text-[10px]'>ARTIST 3</p>
        </div>
      </div>
    );

  return (
    <div
      style={{
        position: 'absolute',
        height: `calc(${difference}% + 30px)`,
        top: `${start}%`,
      }}
      className={`bg-[#6662EF] bg-opacity-30 w-full top-0 min-h-[30px]`}
    >
      <div className='w-full p-2 bg-gradient-to-b from-[#7773FA] to-[#5652E5]'>
        <p className='text-center w-full text-[10px]'>ARTIST {idx}</p>
      </div>
    </div>
  );
}

function LyricPreview({
  noofArtists,
  duration,
  currentTime,
  lyricArray,
  filename,
}) {
  // console.log({ noofArtists, duration, currentTime, lyricArray });

  const indicatorStyle = {
    height: '3px',
    position: 'absolute',
    width: '100%',
    background: '#7773FA',
    top: `clamp(0px, ${(currentTime / duration) * 100}%, 200px)`,
    transition: 'top 1s',
    zIndex: '99',
  };

  // const containerStyle = {
  //   minHeight: `${duration * 10}px`,
  //   top: `clamp( calc(400px - 100%), ${
  //     (currentTime / duration) * 100 * -1
  //   }%, 0px)`,
  //   transition: 'top 1s',
  //   zIndex: '1',
  // };
  return (
    <>
      <div className='w-[55%] h-full text-white text-xs relative overflow-hidden'>
        <div className='flex bg-[#222434] opacity-100 border-y z-50 border-[#979797] border-opacity-20'>
          {Array.from({ length: noofArtists }, (_, index) => (
            <div className='w-full p-2 border-r border-opacity-30 border-[#979797]'>
              <p className='text-center w-full'>ARTIST {index + 1}</p>
            </div>
          ))}
        </div>
        <div
          // style={containerStyle}
          className='flex border-y z-10 border-[#979797] h-[90%] border-opacity-20 absolute w-full'
        >
          <div style={indicatorStyle} />
          {Array.from({ length: noofArtists }, (_, index) => (
            <div className='h-full relative flex-1 border-r border-opacity-30 border-[#979797]'>
              {index === 0 ? (
                <>
                  {lyricArray &&
                    lyricArray.length &&
                    lyricArray.map((lyric) => {
                      if (lyric.lyricArtist == 1)
                        return (
                          <Artist duration={duration} lyric={lyric} idx={1} />
                        );
                      return <></>;
                    })}
                </>
              ) : index === 1 ? (
                <>
                  {lyricArray &&
                    lyricArray.length &&
                    lyricArray.map((lyric) => {
                      if (lyric.lyricArtist == 2)
                        return (
                          <Artist duration={duration} lyric={lyric} idx={2} />
                        );
                      return <></>;
                    })}
                </>
              ) : index === 2 ? (
                <>
                  {lyricArray &&
                    lyricArray.length &&
                    lyricArray.map((lyric) => {
                      if (lyric.lyricArtist == 3)
                        return (
                          <Artist duration={duration} lyric={lyric} idx={3} />
                        );
                      return <></>;
                    })}
                </>
              ) : (
                <>
                  {lyricArray &&
                    lyricArray.length &&
                    lyricArray.map((lyric) => {
                      if (lyric.lyricArtist == 4)
                        return (
                          <Artist duration={duration} lyric={lyric} idx={1} />
                        );
                      return <></>;
                    })}
                </>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className='h-full w-[40%] pb-2'>
        <div className='flex flex-col justify-between h-full p-4 rounded-xl bg-black bg-opacity-30'>
          {lyricArray &&
            lyricArray.length &&
            lyricArray
              .sort((a, b) => {
                const timeA = convertTimeToSeconds(a.fromTime);
                const timeB = convertTimeToSeconds(b.fromTime);
                return timeA - timeB;
              })
              .map((lyric) => {
                let current = currentTime;
                let isInRange = isCurrentTimeInRange(
                  current,
                  lyric.fromTime,
                  lyric.toTime
                );
                return (
                  <p
                    className={`text-white ${
                      isInRange ? 'opacity-100' : 'text-opacity-50'
                    } `}
                  >
                    {lyric.lyricText}
                  </p>
                );
              })}
        </div>
      </div>
    </>
  );
}

export default LyricPreview;
