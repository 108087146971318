import React from 'react';
import Button from '../../components/common/Button';
import Header from '../../components/common/Header';
import DashImg1 from '../../assets/images/dashboard/DashImg.png';
import DailyFollower from '../../assets/icons/dashboard/DailyFollowers.svg';
import DastStat1 from '../../assets/icons/dashboard/dash-stat1.svg';
import DastStat2 from '../../assets/icons/dashboard/dash-stat2.svg';
import DastStat3 from '../../assets/icons/dashboard/dash-stat3.svg';
import DastStat4 from '../../assets/icons/dashboard/dash-stat4.svg';

import Active1 from '../../assets/images/dashboard/active1.png';
import Active2 from '../../assets/images/dashboard/active2.png';
import Active3 from '../../assets/images/dashboard/active3.png';
import Active4 from '../../assets/images/dashboard/active4.png';
import { useNavigate } from 'react-router-dom';

const Dashboard = ({ setDarkMode }) => {
  const navigate = useNavigate();
  return (
    <div className='max-h-screen overflow-hidden'>
      <Header />
      <div className='px-[8%]'>
        <div className=' h-[80vh] w-full my-10 mb-11 '>
          <div className='w-full h-full flex md: gap-4'>
            <div className='w-[60vw] h-full card-gradient rounded-3xl p-[3%] flex flex-col justify-between gap-8'>
              <div className='flex'>
                <p className='text-2xl text-white font-medium'>Dashboard</p>
              </div>
              <div className='h-[35vh] w-full gap-8 flex'>
                <div className='w-[25%] h-full rounded-2xl flex flex-col justify-between'>
                  <p className='text-6xl text-white font-bold'>290</p>
                  <div className='flex gap-5 pb-5 border-b border-white items-center'>
                    <img className='h-10' src={DailyFollower} />
                    <p className='text-white text-sm'>
                      Followers <br /> Daily
                    </p>
                  </div>
                  <img src={DashImg1} />
                  <p className='text-violet-500 text-sm underline'>
                    Refresh your daily stats here
                  </p>
                </div>
                <div className='w-[75%] bg-gradient-to-t from-indigo-700 to-indigo-500 h-full rounded-2xl'></div>
              </div>
              <div className='h-[25vh] w-full card-gradient rounded-2xl flex '>
                <div className='flex flex-col w-full h-full justify-between items-center py-8 px-4 border-r border-white border-opacity-30'>
                  <div className='flex gap-3 w-full'>
                    <img src={DastStat1} />
                    <p className='text-sm text-white text-opacity-50'>
                      Total Views
                    </p>
                  </div>
                  <p className='text-5xl text-white font-semibold w-full'>
                    1.5m
                  </p>
                  <div className='px-2 w-full'>
                    <div className='h-[3px] w-full bg-white bg-opacity-30 rounded'>
                      <div className='h-[3px] w-[50%] bg-purple-400 rounded'></div>
                    </div>
                  </div>
                </div>
                <div className='flex flex-col w-full h-full justify-between items-center py-8 px-4 border-r border-white border-opacity-30'>
                  <div className='flex gap-3 w-full'>
                    <img src={DastStat2} />
                    <p className='text-sm text-white text-opacity-50'>
                      Recordings
                    </p>
                  </div>
                  <p className='text-5xl text-white font-semibold w-full'>1m</p>
                  <div className='px-2 w-full'>
                    <div className='h-[3px] w-full bg-white bg-opacity-30 rounded'>
                      <div className='h-[3px] w-[50%] bg-pink-300 rounded'></div>
                    </div>
                  </div>
                </div>
                <div className='flex flex-col w-full h-full justify-between items-center py-8 px-4 border-r border-white border-opacity-30'>
                  <div className='flex gap-3 w-full'>
                    <img src={DastStat3} />
                    <p className='text-sm text-white text-opacity-50'>
                      Trending
                    </p>
                  </div>
                  <p className='text-5xl text-white font-semibold w-full'>
                    529k
                  </p>
                  <div className='px-2 w-full'>
                    <div className='h-[3px] w-full bg-white bg-opacity-30 rounded'>
                      <div className='h-[3px] w-[50%] bg-green-300 rounded'></div>
                    </div>
                  </div>
                </div>
                <div className='flex flex-col w-full h-full justify-between items-center py-8 px-4'>
                  <div className='flex gap-3 w-full'>
                    <img src={DastStat4} />
                    <p className='text-sm text-white text-opacity-50'>
                      Followers
                    </p>
                  </div>
                  <p className='text-5xl text-white font-semibold w-full'>
                    12k
                  </p>
                  <div className='px-2 w-full'>
                    <div className='h-[3px] w-full bg-white bg-opacity-30 rounded'>
                      <div className='h-[3px] w-[50%] bg-orange-300 rounded'></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex flex-col w-[40vw] gap-4'>
              <div className='w-full h-full p-8 pb-12 card-gradient rounded-3xl flex flex-col justify-between  items-center'>
                <p className='text-xl font-semibold text-white'>My Tracks</p>
                <div className='w-full flex justify-center items-center'>
                  <img src={Active1} className='z-40' />
                  <img src={Active2} className='ml-[-12px] z-30' />
                  <img src={Active3} className='ml-[-12px] z-20' />
                  <img src={Active4} className='ml-[-12px] z-10' />
                  <div className='h-12 w-12 rounded-full bg-[#5652E5] ml-[-12px] flex justify-center items-center'>
                    <p className='text-white'>+42</p>
                  </div>
                </div>
                <Button
                  onClick={() => navigate('/myTracks')}
                  title={'My Tracks'}
                  add={false}
                />
              </div>
              <div className='w-full h-full p-8 pb-12 card-gradient rounded-3xl flex flex-col justify-between  items-center'>
                <p className='text-xl font-semibold text-white'>
                  Create a New Track
                </p>
                <p className='text-center text-white text-opacity-50'>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s,
                </p>
                <Button
                  title={'Create Now'}
                  add={true}
                  onClick={() => navigate('/createTrack')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
