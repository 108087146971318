import * as actionTypes from '../ActionTypes';

export const getUserProfileData = (payload) => {
  return {
    type: actionTypes.USER_PROFILE_DATA,
    payload: payload,
  };
};

export const setUserProfileUrl = (payload) => {
  return {
    type: actionTypes.USER_PROFILE_URL,
    payload: payload,
  };
};
