import React, { useEffect, useRef, useState } from 'react';
import Logo from '../../assets/icons/header/logo.svg';
import Notification from '../../assets/icons/header/notification.svg';
import Light from '../../assets/icons/header/light-mode.svg';
import ProfilePic from '../../assets/icons/header/profilepic.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProfileData } from '../../store/UserData/UserDataAction';
import { FaRegUser } from 'react-icons/fa6';
import { FiLogOut } from 'react-icons/fi';
import userDataHook from '../../screens/dashboard/hooks/userDataHook';

const Header = ({ setDarkMode }) => {
  const [isProfileClicked, setIsProfileClicked] = useState(false);
  const [isNotificationClicked, setIsNotificationClicked] = useState(false);
  const { profilePicUrl } = useSelector((state) => state.userReducer);
  const { logout, fetchUserProfile } = userDataHook();
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsProfileClicked(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  useEffect(() => {
    fetchUserProfile();
  }, []);

  return (
    <header className='flex justify-between px-[8%] py-4 border-b border-white border-opacity-10 '>
      <div className='flex gap-3 items-center'>
        <img src={Logo} />
        <p className='text-white font-semibold'>Karaokee</p>
      </div>
      <div className='flex gap-6 items-center relative '>
        <img
          className='cursor-pointer'
          src={Light}
          onClick={() => setDarkMode((preState) => !preState)}
        />
        <img
          src={Notification}
          className='cursor-pointer'
          onClick={() => {
            setIsProfileClicked(false);
            setIsNotificationClicked(!isNotificationClicked);
          }}
        />
        <div className='w-[36px] h-[36px] overflow-hidden rounded-full'>
          <img
            src={profilePicUrl ? profilePicUrl : ProfilePic}
            className='w-full h-full object-cover cursor-pointer'
            onClick={() => {
              setIsProfileClicked(!isProfileClicked);
              setIsNotificationClicked(false);
            }}
          />
        </div>

        {isProfileClicked && (
          <div
            ref={menuRef}
            className='flex z-10 text-white flex-col absolute right-0 top-full mt-5 rounded-lg p-2 gap-2 bg-[#141227] shadow-sm'
          >
            <div className='cursor-pointer text-[#8A8CB3] hover:text-white p-2 flex gap-2 items-center justify-between'>
              <FaRegUser className='text-lg cursor-pointer' />
              <p>Profile</p>
            </div>
            <span className='w-full h-[1px] bg-slate-600' />
            <div
              onClick={logout}
              className='cursor-pointer text-[#8A8CB3] hover:text-white p-2 flex gap-2 items-center justify-between'
            >
              <FiLogOut className=' text-lg cursor-pointer' />
              <p>Logout</p>
            </div>
          </div>
        )}

        {isNotificationClicked && (
          <div className='flex z-50 text-white flex-col absolute right-10 top-full mt-5 rounded-lg gap-1 bg-[#141227] shadow-sm items-center'>
            <div className='border-[#8A8CB3] border-b-2  cursor-pointer w-max  border-opacity-50  text-[#8A8CB3] p-4 flex flex-col  gap-1 items-start justify-between'>
              <p className=' w-[20vw]'>
                Lorem ipsum dolor sit amet, sit amet, consectetur.
              </p>
              <p className=' w-[20vw] text-sm'>1 hour ago</p>
            </div>
            <div className='border-[#8A8CB3] border-b-2  cursor-pointer w-max  border-opacity-50  text-[#8A8CB3] p-4 flex flex-col  gap-1 items-start justify-between'>
              <p className=' w-[20vw]'>
                Lorem ipsum dolor sit amet, sit amet, consectetur.
              </p>
              <p className=' w-[20vw] text-sm'>1 day ago</p>
            </div>

            <div className='text-[#8A8CB3] p-4 cursor-pointer w-full text-center'>
              View All
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
