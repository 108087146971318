import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { postEmailVerficationAction, postLoginAction, postResendVerificationAction } from '../../../store/LoginAndRegister/LoginAndRegisterAction';
import { postSignUpAction } from '../../../store/LoginAndRegister/LoginAndRegisterAction';
import Toastify from '../../../components/Universal/Toastify';
import { emailValidation, passwordValidation, phoneNumberValidation, repeatPasswordValidation, validations } from '../../../constants/ValidationConstants';

export const LoginPageHooks = () => {
    const [currentForm, setCurrentForm] = useState("login");
    const navigate = useNavigate();
    return {
        currentForm,
        setCurrentForm,
        navigate
    }
}

export const LoginFormHooks = (setCurrentForm) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isFieldRequired = (fieldName) => {
        const requiredFields = [
            "email",
            "password",
        ];
        return requiredFields.includes(fieldName);
    };

    const initialLoginValues = {
        email: '',
        password: '',
    }
    const initialErrors = {
        email: {
            message: "Invaide Email ID",
            validateFunction: emailValidation
        },
        password: '',

    }
    const [viewPassword, setViewPassword] = useState(false);
    const [validate, setValidate] = useState(true);
    const [loginValues, setLoginValues] = useState(initialLoginValues);
    const [loginErrors, setLoginErrors] = useState(initialErrors);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleCancelValidate = () => {
        setValidate(true)
    }

    const handleChange = (event) => {

        const { name, value } = event.target;

        setLoginValues((prevState) => ({
            ...prevState,
            [name]: value
        }));
        setIsSubmitted(false)
    }

    const localEmail = localStorage.getItem("email");

    const initialValidateValues = {
        email: localEmail,
        emailotp: '',
    }

    const [otpValue, setOtpValue] = useState(initialValidateValues);

    const handleLoginSubmit = (event) => {
        event.preventDefault();
        if (validate) {

            {
                emailValidation(loginValues.email) && dispatch(
                    postLoginAction({
                        apiPayloadRequest: loginValues,
                        callback: loginResponse
                    }))
            }
        } else {
            dispatch(
                postResendVerificationAction({
                    apiPayloadRequest: { email: loginValues.email },
                    callback: validateEmailResponse,
                }));
        }
    }

    const loginResponse = (response) => {
        if (response?.type === 1) {
            localStorage.setItem("token", response?.data?.AuthenticationResult?.AccessToken);
            navigate("/dashboard");
        } else {
            if (response.message.name === "UserNotConfirmedException") {
                setValidate(prevState => !prevState);
            }
            else {
                Toastify("Login Failed");
            }
        }
    }

    const validateEmailResponse = (response) => {
        if (response.type === 1) {
            setCurrentForm('validate');
            Toastify("OTP Sent To Your Email ID");
            localStorage.setItem("email", loginValues.email);
        } else {
            handleCancelValidate();
            Toastify("Something Went Wrong! Try Again");
        }
    }

    const handleValidateChange = (event) => {
        const { name, value } = event.target;
        setOtpValue((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleValidateSubmit = (event) => {
        event.preventDefault();
        dispatch(
            postEmailVerficationAction({
                apiPayloadRequest: otpValue,
                callback: validateResponse
            })
        )

    }

    const validateResponse = (response) => {
        if (response?.type === 1) {
            Toastify("OTP Verification Successful")
            setCurrentForm("login");
            localStorage.clear("email");
        } else {
            Toastify("Something Went Wrong! Try Again")
        }
    }

    return {
        loginValues,
        handleChange,
        handleLoginSubmit,
        validate,
        otpValue,
        handleCancelValidate,
        handleValidateSubmit,
        handleValidateChange,
        viewPassword,
        setViewPassword,
        loginErrors,
        isFieldRequired,
        navigate,
        isSubmitted,
        setIsSubmitted
    }

}



export const SignUpFormHooks = (setCurrentForm) => {

    const dispatch = useDispatch();

    const isFieldRequired = (fieldName) => {
        const requiredFields = [
            "phone",
            "email",
            "password",
            "repeatPassword"
        ];
        return requiredFields.includes(fieldName);
    };

    const initialValues = {
        phone: '',
        email: '',
        password: '',
        repeatPassword: '',
    }

    const initialErrors = {
        phone: {
            message: "Invaide Phone Number",
            validateFunction: phoneNumberValidation
        },
        email: {
            message: "Invaide Email ID",
            validateFunction: emailValidation
        },
        password: {
            message: 'Create a Stronger Password!',
            validateFunction: passwordValidation
        },
        repeatPassword: {
            message: "Password Doesn't Match",
            validateFunction: repeatPasswordValidation
        },
    }
    const plus = "+";
    const [viewPassword, setViewPassword] = useState(false);
    const [viewRepeatPassword, setViewRepeatPassword] = useState(false);
    const [signupValues, setSignupValues] = useState(initialValues);
    const [signupErrors, setSignupErrors] = useState(initialErrors);
    const [termsAndConditions, setTermsAndConditions] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleCheckboxChange = (event) => {
        setTermsAndConditions(event.target.checked);
    };

    const handlePhoneChange = (value) => {
        console.log(value, "value123")
        setSignupValues((prevState) => ({
            ...prevState,
            ["phone"]: value
        }));
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setSignupValues((prevState) => ({
            ...prevState,
            [name]: value
        }));
        setIsSubmitted(false)
    }

    const handleSignUpSubmit = (event) => {
        event.preventDefault();
        if (
            // phoneNumberValidation(signupValues.phone)
            // &&
            emailValidation(signupValues.email)
            &&
            repeatPasswordValidation(signupValues.password, signupValues.repeatPassword)
            &&
            termsAndConditions
        ) {
            dispatch(postSignUpAction({
                apiPayloadRequest: {
                    phone: plus.concat(signupValues.phone),
                    email: signupValues.email,
                    password: signupValues.password,
                    repeatPassword: signupValues.repeatPassword,
                },
                callback: onSuccuss
            }))
        }

    }

    const onSuccuss = (response) => {
        if (response.type === 1) {
            localStorage.setItem("email", signupValues.email);
            setCurrentForm('validate');
            Toastify("OTP Sent To Your Email");
        }
    }

    return {
        handleSignUpSubmit,
        handleChange,
        handleCheckboxChange,
        signupValues,
        termsAndConditions,
        viewPassword,
        viewRepeatPassword,
        setViewPassword,
        setViewRepeatPassword,
        signupErrors,
        isFieldRequired,
        isSubmitted,
        repeatPasswordValidation,
        setIsSubmitted,
        handlePhoneChange
    }

}
